import React from "react";
import Link from "next/link";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import footerBG from "@/public/images/assets/footerBG.svg";
import GradientElement from "../GradientElement";
const Footer = () => {
  return (
    <footer
      className="bg-darkPurple text-white py-12 bg-contain lg:bg-cover relative"
      style={{
        backgroundImage: `url(${footerBG.src})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <span
        className={`z-10 w-full max-w-[200px] h-[108px] -rotate-45 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#8F6AF8] blur-[160px] rounded-full`}
      ></span>
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-8 ">
          {/* Newsletter Section */}
          <div className="col-span-1 lg:col-span-3">
            <h3 className="text-lg font-medium mb-4">Receive latest news</h3>
            <div className="flex flex-wrap gap-4 mb-4">
              <input
                type="email"
                placeholder="Enter your e-mail address"
                className="bg-darkerPurple text-lightPurple p-3 rounded-lg flex-grow"
              />
              <button className="bg-brightPurple text-white py-3 px-6 rounded-lg whitespace-nowrap">
                Get Started
              </button>
            </div>
            <p className="text-lightPurple text-sm">
              By entering your e-mail address you confirm that you agree to
              subscribe to our newsletter.
            </p>
          </div>

          {/* Info Section */}
          <div>
            <h3 className="text-lg font-medium mb-4">Info</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  href="/#contact"
                  className="text-lightPurple hover:text-white transition-colors"
                >
                  Contact US
                </Link>
              </li>
              {/* <li>
                <Link
                  href="/about"
                  className="text-lightPurple hover:text-white transition-colors"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  href="/services"
                  className="text-lightPurple hover:text-white transition-colors"
                >
                  Services
                </Link>
              </li> */}
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h3 className="text-lg font-medium mb-4">Contact</h3>
            <p className="text-lightPurple">support@vulnvision.com</p>
            {/* <p className="text-lightPurple">+2001204146334</p> */}
          </div>

          {/* Social Media Section */}
          <div>
            <h3 className="text-lg font-medium mb-4">Social Media</h3>
            <div className="flex gap-2">
              <a
                href="#"
                className="bg-darkerPurple p-2 rounded-lg hover:bg-brightPurple transition-colors"
                aria-label="Facebook"
              >
                <FaFacebookF className="w-5 h-5" />
              </a>
              <a
                href="#"
                className="bg-darkerPurple p-2 rounded-lg hover:bg-brightPurple transition-colors"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn className="w-5 h-5" />
              </a>
              <a
                href="#"
                className="bg-darkerPurple p-2 rounded-lg hover:bg-brightPurple transition-colors"
                aria-label="Twitter"
              >
                <FaTwitter className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t border-darkerPurple">
          <h2 className="text-3xl font-bold mb-4 text-center">Vulnvision.</h2>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center gap-4 mb-4 md:mb-0 flex-wrap">
              <Link
                href="/privacy"
                className="text-lightPurple hover:text-white transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                href="/terms"
                className="text-lightPurple hover:text-white transition-colors"
              >
                Terms of Use
              </Link>
              <Link
                href="/#contact"
                className="text-lightPurple hover:text-white transition-colors"
              >
                Contact Us
              </Link>
            </div>
            <p className="text-center md:text-right mt-4">
              Copyright © 2024 Vulnvision. All rights reserved · Developed by
              Vulnvision team
              {/* Made With{" "}
              <Link href={"https://rascoda.com/"} target="_blank" className="text-primaryColor underline font-bold">
                Rascoda
              </Link> */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { FaArrowRight } from "react-icons/fa";
import DemoButton from "../Buttons/DemoButton";
import { GoPlay } from "react-icons/go";
import company1 from "@/public/images/companies/1.png";
import company2 from "@/public/images/companies/2.png";
import company3 from "@/public/images/companies/3.png";
import company4 from "@/public/images/companies/4.png";
// import heroImage from "@/public/images/assets/heroImage.svg";
import heroImage from "@/public/images/assets/heroImage.png";
import Image from "next/image";
import GradientElement from "../GradientElement";
import Link from "next/link";

type Props = {};

const Hero = (props: Props) => {
  return (
    <section className="hero container text-center py-10">
      <div className="flex flex-col items-center justify-center gap-4">
        {/* 
        <div
          className="cursor-pointer flex items-center gap-2 p-2 pe-4 rounded-3xl"
          style={{
            boxShadow: "0px 0px 0px 3px rgba(143, 105, 248, 0.10)",
            backgroundImage:
              "linear-gradient(0deg, rgba(99, 46, 249, 0.13) 0%, rgba(99, 46, 249, 0.13) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%)",
          }}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <button className="main-btn bg-primaryColor">join us</button>
          <span>brilliance in execution</span>
          <FaArrowRight />
        </div>
        */}
        <div
          className="flex flex-col gap-4 relative"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h2 className="max-w-4xl text-3xl md:text-6xl leading-relaxed font-bold capitalize hero-text">
            Elevate Your Security—The Future of Protection Begins Here
          </h2>
          <p className="text-lg font-normal">
            Unleash Cyber Power, Defend Your Domain
          </p>
          <GradientElement />
        </div>
        <div
          className="flex items-center gap-5"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <DemoButton />
          {/* 
          <button className="demo-btn bg-[#632EF933] flex items-center justify-center gap-2">
            <GoPlay />
            Watch Video
          </button> 
*/}
        </div>
        <div
          className="flex flex-col gap-4 mt-6"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div className="text-center text-[#e9e9ea] text-lg gradient-text capitalize">
            Trusted by 300+ companies
          </div>
          <div className="flex gap-8 items-center justify-center flex-wrap">
            <Link href={"https://rascoda.com/"} target="_blank">
              <Image
                src={company1}
                alt="Rascoda"
                data-aos="zoom-in"
                data-aos-delay="500"
              />
            </Link>
            <Image
              src={company2}
              alt="Paymob"
              data-aos="zoom-in"
              data-aos-delay="600"
            />
            <Image
              src={company3}
              alt="SmartLink"
              data-aos="zoom-in"
              data-aos-delay="700"
            />
            <Image
              src={company4}
              alt="Cerberoge"
              data-aos="zoom-in"
              data-aos-delay="700"
            />
          </div>
        </div>
        <div className="w-full mt-10" data-aos="fade-up" data-aos-delay="1000">
          <Image src={heroImage} alt="Vulnvision" className="mx-auto" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
